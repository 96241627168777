<template>
  <div>
    <app-navbar />
    <div class="auth-wrapper auth-v1 px-2">
      <div class="py-2">
        <!-- Login v1 -->
        <b-card class="mb-0 login-v1">
          <b-card-text class="mb-2 text-left font-weight-bolder">
            {{ $t('Please type your password twice') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="restoreVal"
            #default="{invalid}"
          >
            <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                label="Password"
                label-for="reset-password-new"
                label-class="inputLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge boxShadow"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="Type..."
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label="Retype Password"
                label-for="reset-password-confirm"
                label-class="inputLabelNormal"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge boxShadow"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="Type..."
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                class="btn"
                type="submit"
                variant="success"
                :disabled="invalid || isSending"
              >
                Reset Password
              </b-button>
            </b-form>
          </validation-observer>

        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput,
  BFormGroup, BCard, BCardText,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import AppNavbar from '@/@core/layouts/components/app-navbar/AppNavbar.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import {scrollToError} from "@core/utils/utils";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    AppNavbar,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      isSending: false,
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },

  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    token() {
      return this.$route.query.token
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.restoreVal.validate().then(success => {
        if (success) {
          this.isSending = true
          useJwt.restorePassword({
            token: this.token,
            password: this.password,
            password_confirmation: this.cPassword,
          }).then(response => {
            this.$router.replace({ name: 'auth-login' }).then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  icon: 'MailIcon',
                  title: response.data.message,
                  variant: 'success',
                },
              })
            })
          })
            .catch(error => {
              this.errorNotification(this, error)
            }).finally(() => {
              this.isSending = false
            })
        } else {
          scrollToError(this, this.$refs.restoreVal)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
